import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0d87ed24"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_ctx.getShowSpinner)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_spinner, {
          name: "lines",
          duration: _ctx.spinnerDuration,
          paused: _ctx.spinnerPaused,
          enabled: _ctx.spinnerEnabled
        }, null, 8, ["duration", "paused", "enabled"])
      ]))
    : _createCommentVNode("", true)
}