import { defineStore } from "pinia";
import pinia from "@/plugins/piniaStore";

//const base = "http://localhost:8000";
//const base = "http://localhost:8080";
//const base = "http://localhost:9000";
const base = "https://milage.ualg.pt";
//const base = "http://webmilage.ualg.pt";
//const base = "http://193.136.227.188:8080";

export const urls = defineStore({
  id: "urls",
  state: () => {
    return {
      //for development
      base: base,

      //   baseURL: `${base}/mlv2/api/`,
      //   staticURL: `${base}/milv2static/`,
      //   mediaURL: `${base}/milv2media/`,
      //   domainURL: `${base}/mlv2/`,

      // // //for productiongit status
      baseURL: `${base}/mlapi/api/`,
      staticURL: `${base}/mlapistatic/`,
      mediaURL: `${base}/mlapimedia/`,
      domainURL: `${base}/mlapi/`,
    };
  },
  getters: {
    getApi: (state) => state.baseURL,
    getDomain: (state) => state.domainURL,
    getStatic: (state) => state.staticURL,
    getMedia: (state) => state.mediaURL,
    getBase: (state) => state.base,
  },
});

export const url = urls(pinia);
