<template>
  <div class="flexbox relative"  :style="getInputCSS">
    <div class="wrapper">
      <div class="input-box-wrapper">

        <TextEditorResponse v-if="isText" :key="selectedTaskIndex" :initialContent="contentFromModal"
          :no-max-height="true" @update-content="onUpdateContent" :keyboard-id="answerStore.keyboard">
        </TextEditorResponse>

        <img v-if="textBtn !== undefined && false && isText === undefined"
          @click="route(textBtn?.route ?? 'text-editor')" :src="textBtn!.src" />
      </div>
    </div>


    <Transition name="icons" v-if="!isWeb">
      <div v-if="((contentFromModal && contentFromModal.ops.length === 0) || !contentFromModal)" class="icons">
        <!-- <span @click="(() => route('/text-editor'))">Text</span> -->

        <div v-if="isOptionsBoxOpen">
          <WksOpenInputPopUpBtn :buttons="modifiedButtons" @click="name => openRoute(name, params)" />
        </div>

        <img v-if="mediaButton && mediaButton.length > 3" :src="plusSvg" @click="toggleOptionsBox" />
        <img v-for="(button, key) in getInputIconButtons" :key="key" :src="button.src"
          @click="openRoute(button.routeName, params)" />
      </div>
    </Transition>
    <Transition name="send">
      <div v-if="contentFromModal && contentFromModal.ops.length > 0" class="send-button" @click="sendText"
        v-html="sendSvg"></div>
    </Transition>

    <div class="icons" v-if="isWeb && ((contentFromModal && contentFromModal.ops.length === 0) || !contentFromModal)">
      <div v-if="isOptionsBoxOpen">
        <WksOpenInputPopUpBtn :buttons="modifiedButtons" @click="r => openRoute(r, params)" />
      </div>
      <img v-if="mediaButton && mediaButton.length > 3" :src="plusSvg" @click="toggleOptionsBox" />
      <WksOpenInputBtn v-for="(button, key) in getInputIconButtons" :key="key" :src="button.src"
        :route-name="button.routeName" @click="handleOpenRoute" />
    </div>
  </div>

</template>

<script setup lang="ts">
import {
  computed,
  ref,
  onMounted,
  watch,
  shallowRef,
  PropType,
  toRefs,
  nextTick,
  onBeforeMount,
} from "vue";
import router from "@/router";
import { getIcon } from "@/helpers/api";
import CssUtils from "@/helpers/cssUtils";
import { IFont, getFont } from "@/helpers/fonts";
import { nanoid } from "nanoid";
import { useOpenInputMediaStore } from "@/modules/a.worksheets/stores/openInputMediaStore";
import { useDeviceStatus } from "@/stores/deviceStatus";
import WksOpenInputPopUpBtn from "@/modules/a.worksheets/views/components/footer/WksOpenInputPopUpBtn.vue";
import WksOpenInputBtn from "@/modules/a.worksheets/views/components/footer/WksOpenInputBtn.vue";
import { TInputButton } from "@/modules/a.worksheets/helpers/wksOpenInput";
import { RouteParamsRaw } from "vue-router";
import { computedAsync } from "@vueuse/core";
import ModalText from "@/globals/modules/ModalText.vue";
import { Quill, QuillEditor } from "@vueup/vue-quill";
import Delta from 'quill-delta';
import { useUserAnswersData } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import TextEditorResponse from "@/globals/modules/TextEditorResponse.vue";



const { isWeb } = toRefs(useDeviceStatus());
const answerStore = useUserAnswersData();

const isOptionsBoxOpen = ref(false);
const props = defineProps({
  mediaButton: {
    type: Array<TInputButton>,
    default: undefined,
  },
  placeholder: { type: String, default: "" },
  textBtn: {
    type: Object as PropType<{ src: string; route: string }>,
    default: undefined,
  },
  isText: { type: Boolean, default: true },
  params: { type: Object as PropType<RouteParamsRaw>, default: () => ({}) },
});
const emit = defineEmits(["set-final-router", "set-media"]);

const inputRef = shallowRef<HTMLTextAreaElement | null>(null);

const nore12 = ref<IFont>();
const nore16 = ref<IFont>();

const plusSvg = ref<string | undefined>();
const sendSvg = ref<string | undefined>();
onMounted(async () => {
  console.log("ISTEXT", props.isText)
  plusSvg.value = await getIcon("1602", "plus");
  nore12.value = await getFont("nore12");
  nore16.value = await getFont("nore16");
  const sendBlobUrl = await getIcon("1610A", "send");
  if (sendBlobUrl) {
    sendSvg.value = await fetch(sendBlobUrl).then((r) => r.text());
  }
});

function route(path: string) {
  isOptionsBoxOpen.value = false;
  router.replace(path);
}

const getInputIconButtons = computed(() => {
  if (props.mediaButton) {
    if (props.mediaButton.length < 3) {
      return props.mediaButton;
    } else if (props.mediaButton.length > 3) {
      return [props.mediaButton[0], props.mediaButton[1]];
    } else {
      return [props.mediaButton[0], props.mediaButton[1], props.mediaButton[2]];
    }
  }
  return [];
});

const modifiedButtons = computedAsync<Array<{ route: string; src: string; svg: string; name: string, routeName: string }>>(async () => {
  if (props.mediaButton && props.mediaButton.length > 3) {
    const buttons = props.mediaButton.slice(2);
    const modButtons = buttons.map(async (btn) => {
      console.log("btn: ", btn)
      return {
        route: btn.route,
        src: btn.src,
        name: btn.name,
        svg: await fetch(btn.src).then((res) => res.text()),
        routeName: btn.routeName
      };
    });
    const resolvedBtn = await Promise.all(modButtons);
    resolvedBtn.forEach((btn) => {
      btn.svg = new CssUtils(btn.svg)
        .setSvgFill("var(--milage-color-primary)")
        .removeSvgAttribute("width")
        .removeSvgAttribute("height")
        .addStyle("width: 60%; height: 60%;")
        .build();
    });
    console.log("resolvedBtn: ", resolvedBtn);
    return resolvedBtn;
  } else {
    return [];
  }
});

const toggleOptionsBox = async () => {
  isOptionsBoxOpen.value = !isOptionsBoxOpen.value;
};


const getInputCSS = computed(() => {
  const backgroundColor = isWeb.value
    ? "var(--worksheet-difficulty-bg)"
    : "#EFEFEF";

  document.documentElement.style.setProperty('--tootlip-toolbar-bck', backgroundColor);

  return {
    "--placeholder-font": isWeb.value ? nore16.value?.["font-family"] : nore12.value?.["font-family"],
    "--placeholder-font-size": isWeb.value ? nore16.value?.["font-size"] : nore12.value?.["font-size"],
    "--placeholder-font-weight": isWeb.value ? nore16.value?.["font-weight"] : nore12.value?.["font-weight"],
    "--placeholder-transform": isWeb.value ? nore16.value?.["text-transform"] : nore12.value?.["text-transform"],
    "--input-background": backgroundColor,
  };
});



const setInputText = (e: InputEvent) =>
  (text.value = e.target ? (e.target as HTMLTextAreaElement).value : "");

const text = ref("");
async function sendText() {
  emit("set-media", contentFromModal.value, "text");
  if (inputRef.value) inputRef.value.value = "";
}

// CROSS PAGE COMMUNICATION
const id = nanoid();


const openInputMediaStore = useOpenInputMediaStore();
watch(openInputMediaStore.openInput, (mapping) => {
  const data = mapping.get(id);
  if (data) {
    emit("set-media", data.data, data.type);
    console.log("WksOpenInput Caught data from the Store!", data);
  }
});

const textAreaRows = ref<number>(1);
function growArea() {
  textAreaRows.value = 5;
}
function shrinkArea(e: FocusEvent) {
  setTimeout(() => {
    textAreaRows.value = 1
  }, 100)
}
// ------


function openRoute(name: string, params: RouteParamsRaw) {
  console.warn("route name: ", name);
  console.warn("params: ", params);
  router.push({
    name,
    params,
  });
}

function handleOpenRoute(routeName: string) {
  console.log("Abrindo rota:", routeName);
  openRoute(routeName, props.params);
}


//Quill

const editSvg = ref<string>();

onBeforeMount(async () => {
  editSvg.value = await getIcon("1501", "edit");
});


const viewerEditorOptions = {
  theme: 'snow',
  modules: {
    toolbar: false,
  }
};


const contentFromModal = ref<Delta | null>(null);

const viewerQuillEditorRef = ref<InstanceType<typeof QuillEditor> & { quill?: Quill } | null>(null);
const selectedTaskIndex = ref<number>();



watch(contentFromModal, async (newDelta) => {
  if (viewerQuillEditorRef.value && newDelta) {
    await nextTick();
    const quill = viewerQuillEditorRef.value.getQuill();

    const compatibleDelta = JSON.parse(JSON.stringify(newDelta));
    quill.setContents(compatibleDelta);
  }
});

function isDeltaEmptyOrOnlyNewlines(delta: Delta): boolean {
  return delta.ops.every(op => {
    if (typeof op.insert === 'string') {
      return op.insert.trim() === '';
    }
    return false;
  });
}


const onUpdateContent = (content: Delta) => {
  if (isDeltaEmptyOrOnlyNewlines(content)) {
    contentFromModal.value = null;

  } else {
    const changed = JSON.stringify(contentFromModal.value) !== JSON.stringify(content);
    console.log("contentFromModal.value", contentFromModal.value, "content", content, "AQUI", changed)
    contentFromModal.value = content;

  }
};

</script>

<style scoped>
.edit-btn {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 110px;
  cursor: pointer;
  transform: translate(50%, -50%);
  z-index: 10;
}

.ion-padding {
  padding-top: 0;
}

.relative {
  position: relative;
}


.wrapper {
  background-color: var(--input-background);
  border-radius: 0.559835185rem;
  width: 100%;
  min-height: 52px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1%;
}

.mobile .wrapper {
  width: 100%;
  background-color: transparent !important;
}

.flexbox {
  min-height: 42px;
  display: flex;
  flex-direction: row;
  gap: 0.559835185rem;
}

textarea,
textarea:focus {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  resize: none;
}

textarea::placeholder,
textarea {
  font-family: var(--placeholder-font);
  font-size: var(--placeholder-font-size);
  font-weight: var(--placeholder-font-weight);
  text-transform: var(--placeholder-transform);
}

textarea:disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.open-input-box {
  max-height: 200px;
  padding: 2% 0;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  overflow-y: hidden;
}


.web .open-input-box {
  padding: 0;
}

.input-box-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  /* Altura máxima do contêiner */
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  z-index: 100;
  /* Garante que o conteúdo fique abaixo da toolbar */
  position: relative;
  /* Cria contexto de posicionamento */
}

.mobile .input-box-wrapper {
  max-width: 100%;
  max-height: 313px;
}

.input-box-wrapper>img {
  margin: auto 0;
  height: 80%;
  aspect-ratio: 1 / 1;
}

.icons {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  flex-grow: 0;
  flex-basis: fit-content;
  flex-shrink: 0;

  overflow: visible;
  position: relative;
}

.icons img {
  max-height: 65%;
  height: 1.5rem;
  max-width: none;
  cursor: pointer;
}

.web .icons img {
  height: 3rem;
}

.send-button {
  cursor: pointer;
  height: 2.5rem;
}


.mobile .icons {
  position: absolute;
  top: -35px;
  right: 10px;
  z-index: 99;
  height: 45px;
  width: 45px;
  gap: 30%;
}

.mobile .send-button {
  position: absolute;
  top: -35px;
  right: 10px;
  z-index: 99;
  height: 32px;
  width: 32px;
}

.mobile .send-button img {
  transform: scale(1);
}

.mobile .input-box-wrapper {
  margin-top: 15px;
}

.send-button img {
  height: 100%;
  aspect-ratio: 1 / 1;
  transform: scale(1.2);
}

.web .send-button img {
  transform: scale(1.6);
}

.send-enter-active,
.send-leave-active {
  transition: all 0.3s ease-in-out;
}

.send-enter-from,
.send-leave-to {
  transform: scale(0);
}

.icons-enter-active,
.icons-leave-active {
  transition: all 0.3s ease-in-out;
}

.icons-enter-from,
.icons-leave-to {
  transform: scale(0);
}

.web .ql-snow .ql-tooltip {
  position: fixed !important;
  left: 80% !important;
  top: 27% !important;
  width: 30% !important;
  transform: translateX(-50%) !important;
  z-index: 1000 !important;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 70%;
  max-width: 600px;
  border-radius: 20px;
  overflow-y: auto;
}
</style>
