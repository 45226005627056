<template>
  <ion-page @click.stop="" class="modal-primary">
    <ion-content>
      <div class="options">
        <img :src="closeIcon" class="close-icon" @click="dismiss" />
        <img 
        v-if="files.every(f => getViewerType(f.type) === 'image')"
        @click="carousel?.rotate" :src="rotationIcon" />
      </div>

      <WksMediaModalCarousel
        ref="carousel"
        :files="files"
        :zoomable="true"
        :initialSlide="index"
      />
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonContent, modalController, IonPage } from "@ionic/vue";
import { ref, onMounted, computed } from "vue";
import { IFont } from "@/helpers/fonts";
import { getIcon } from "@/helpers/api";
import { IFile } from "@/modules/a.worksheets/stores/worksheetDataStore";
import WksMediaModalCarousel from "@/modules/a.worksheets/views/components/WksMediaModalCarousel.vue";
import { IFileResolved } from "@/modules/a.worksheets/helpers/useIFileResolver";
import { getViewerType } from "@/globals/helpers/media/mimeTypeUtils";

const props = defineProps({
  modalBoxTitle: String,
  selectedValue: String,
  rightButtonLabel: String,
  middleButtonLabel: String,
  leftButtonLabel: String,
  files: { type: Array<IFileResolved>, default: () => null },
  index: { type: Number, default: 0 },
});

const dismiss = () => modalController.dismiss(null, "close");
const leftArrow = ref<string>();
const closeIcon = ref<string>();
const rotationIcon = ref<string>();
const carousel = ref<InstanceType<typeof WksMediaModalCarousel> | null>(null);
onMounted(async () => {
  console.log("MODAL:", props.files)
  leftArrow.value = await getIcon("1010", "left_arrow");
  closeIcon.value = await getIcon("1010", "close");
  rotationIcon.value = await getIcon("1010", "rotate-right");
});
</script>

<style scoped>
ion-title {
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
ion-toolbar {
  padding: 0 5%;
  --ion-safe-area-top: 8%;
}
.options {
  position: absolute;
  top: 4%;
  right: 4%;
  z-index: 100;
  display: flex;
  gap: 2%;
  align-items: center;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  flex-direction: row-reverse;
}
.options > * {
  box-sizing: content-box;
  width: 8%;
  max-width: 30px;
  aspect-ratio: 1 / 1;
  cursor: pointer;

  background-color: rgba(255, 255, 255, 0.75);
  padding: 1%;
  border-radius: 50%;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.options > *:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 1);
}

.web .close-icon {
  width: 3%;
}
</style>
