<template>
  <div class="options-box" :style="getBtnCss">
    <div v-for="(button, key) in buttons" @click="$emit('click', button.routeName)" :style="nore12" :key="key"
      class="option-button-wrapper">
      <div class="option-button" v-html="button.svg"></div>
      <span> {{ button.name }} </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getFont } from "@/helpers/fonts";
import { useDeviceStatus } from "@/stores/deviceStatus";
import { StyleValue, computed, onBeforeMount, ref, toRefs } from "vue";

defineProps({
  buttons: {
    type: Array<{
      route: string;
      src: string;
      svg: string;
      name: string;
      routeName: string;
    }>,
    required: true,
  },
});
defineEmits<{
  (event: "click", routeName: string): void;
}>();

const nore12 = ref<StyleValue>();
onBeforeMount(async () => {
  nore12.value = (await getFont("nore12")) as StyleValue;
});

const { isWeb } = toRefs(useDeviceStatus());
const getBtnCss = computed(() => {
  if (isWeb.value) {
    return {
      border: "solid var(--worksheet-difficulty-border) 1px",
    };
  }
  return {};
});
</script>

<style scoped>
.options-box {
  padding: 20px 30px;
  background-color: #efefef;
  position: absolute;
  top: -24vh;
  left: -150%;
  width: 50vw;
  height: 23vh;
  overflow: visible;
  color: var(--milage-color-primary);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}


.mobile .options-box {
  padding: 20px 30px;
  background-color: #efefef;
  position: static;
  top: -12vh;
  left: -182%;
  width: 50vw;
  height: 11vh;
  overflow: visible;
  color: var(--milage-color-primary);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.option-button-wrapper {
  width: 30%;
  height: auto;
  aspect-ratio: 1 / 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.web .option-button-wrapper {
  width: 4rem;
}

.option-button {
  border-radius: 50%;
  background-color: white;
  border: solid 2px var(--milage-color-primary);

  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
