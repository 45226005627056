

import { defineStore } from 'pinia';
import { IHomepageUserResponse, useFooterData } from "@/globals/stores/storeFooterData";
import requestService from "@/services/requests";
import { AxiosResponse } from 'axios';

interface IHomepageUserResponse2 extends IHomepageUserResponse {
    drpdown_list: Array<IDropdownClassItem | IDropdownMeItem>
}
//---------------------------
interface IDropdownItem {
    kind: 'me' | 'mentee' | 'classroom',
    name: string,
    shortname: string,
    uuid: string,
    avatar: string,
    stats: number[]
}
interface IDropDownUser {
    itemSelected: number,
    dropdownList: Array<IDropdownClassItem | IDropdownMeItem>,
}

interface IDropdownClassItem extends IDropdownItem {
    kind: 'classroom',
    school: { uuid: string, name: string },
    num_students: number,
    code_for_students: string,
    code_for_teachers: string,
    course_name: string,
    is_owner: boolean,
}
interface IDropdownMeItem extends IDropdownItem {
    kind: 'me' | 'mentee'
}

//--------------------------------

const useDropdownUser = defineStore("DropdownData", {
    state: (): IDropDownUser =>
        ({
            itemSelected: 0,
            dropdownList: [
                {
                    "kind": "me",
                    "name": "",
                    "shortname": "",
                    "uuid": "46d72364-2edf-40dd-af30-de552ba388ac",
                    "avatar": "avatars/user-avatar.svg",
                    "stats": [3, 1, 3, 1] //[classes, courses, points, badges] 
                },
                {
                    "kind": "mentee",
                    "uuid": "9938c531-0ee9-40f5-a405-5f3386aca80f",
                    "name": "",
                    "shortname": "",
                    "avatar": "avatars/user-avatar.svg",
                    "stats": [0, 0, 0, 0] //[classes, courses, points, badges] 
                },
                {
                    "kind": "classroom",
                    "name": "",
                    "shortname": "",
                    "uuid": "37adad59-3580-4234-8cec-b0748da38ce2",
                    "avatar": "avatars/class-avatar.svg",
                    "stats": [4, 16] //[students, worksheets] 
                }]
        }) as IDropDownUser,
    getters: {
        getIsUser: (state) => state.dropdownList[state.itemSelected].kind !== 'classroom',
        getKind: (state) => state.dropdownList[state.itemSelected].kind,
        getName: (state) => state.dropdownList[state.itemSelected].name,
        getShortname: (state) => state.dropdownList[state.itemSelected].shortname,
        getUUID: (state) => state.dropdownList[state.itemSelected].uuid,
        getAvatar: (state) => state.dropdownList[state.itemSelected].avatar,
        getStats: (state) => state.dropdownList[state.itemSelected].stats,
        changeSelectedItem: (state) => (p: number) => state.itemSelected = p,
        getSelectedItem: (state) => state.itemSelected,
        getItem: (state) => state.dropdownList[state.itemSelected],
    },
    actions: {
        async updateStats() {
            if (this.dropdownList.length === 0) {
                console.error("Dropdown list is empty.");
                return;
            }
        
            const user = this.dropdownList[this.itemSelected].uuid;  
            try {
                const response = await requestService.getWithParams<{ stats: number[] }>("user-info", { user: user });
                if (response.status >= 200 && response.status < 300 && response.data.stats) {
                    this.dropdownList[this.itemSelected].stats = response.data.stats;
                } else {
                    console.error("Failed to fetch stats or stats are missing in the response", response);
                }
            } catch (error) {
                console.error("Error fetching stats:", error);
            }
        },
        deleteUser(uuid: string) {
            const index = this.dropdownList.findIndex(e => e.uuid === uuid)
            if (index >= 0) {
                this.dropdownList.splice(index, 1);
                this.itemSelected = this.dropdownList.length - 1;
            }
        },
        archiveUser(uuid: string) {
            console.error('archive user has not been implemented yet, User will be deleted instead')
            this.deleteUser(uuid)
        },
        updateUser(uuid: string, item: IDropdownItem) {
            const index = this.dropdownList.findIndex(e => e.uuid === uuid)
            if (index >= 0) {
                this.dropdownList[index].name = item.name
            }
        },
        getUser(uuid: string) {
            const index = this.dropdownList.findIndex(e => e.uuid === uuid)
            if (index >= 0) {
                return this.dropdownList[index]
            }
        },
        async fetchClassrooms(): Promise<AxiosResponse<IDropdownClassItem[]>> {
            const response = await requestService.get<IDropdownClassItem[]>('homepage-user-classrooms');
            return response;
        },
        async update() { // userAccountUUID
            const uuid = this.dropdownList[0].uuid;
            const resp = await requestService.getWithParams<IHomepageUserResponse2>("homepage-user", { uuid: uuid });
            if (resp.status >= 200 && resp.status < 300) {
                this.dropdownList = resp.data.drpdown_list
                const useFooterDataStore = useFooterData()
                useFooterDataStore.setHomepageUserFooterData(resp.data)
            }
        },
        setOfflineMode() {
            this.itemSelected = 0;
        }
    }
})

export { useDropdownUser, IDropdownItem, IDropdownClassItem, IDropdownMeItem }