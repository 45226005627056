import { defineStore } from "pinia";
import { Ref, computed, ref, watch } from "vue";
import { nanoid } from "nanoid";
import { serializeTasks } from "@/persistentStorage/wks_storage/customStringify";
import requestService from "@/services/requests";
import { useLearnUserFiltersStore } from "@/modules/3.learn/stores/learnUserFiltersStore";
import { fetchLocalIFile, deriveIFileId } from "@/helpers/api";
import memoryManager from "@/modules/j.createWorksheets/helpers/MemoryManager";
import { watchOnce } from "@vueuse/core";
import { spinnerManager } from "@/globals/pages/spinnerControl";
import { removeBlob, resolvedIFileNewVersion } from "../helpers/fileHandler";
import { Delta } from "@vueup/vue-quill";

interface IWorksheet {
  uuid: string;
  name: string;
  wks_type: number;
  wks_type_str: string;

  author: string;

  difficulty_level: number;
  difficulty_level_str: string;

  creation_status: number;
  comment: string;
  is_ok: boolean | null;

  extra_info: IFile | null;
  learning_scenario: IFile | null;

  selectedTaskUUID: string;
  selectedActivityUUID: string;

  tasks: Array<ITask0>;
}

enum EvaluationScale {
  QUANTITATIVE = 1,
  QUALITATIVE = 2,
}

interface IFileGroup {
  uuid: string;
  order: number;
  file: IFile;
  textrtf: Delta | null;
}

interface IFile {
  filename: string;
  filetype: string;
  filepath: string;
  isToUpdate: boolean;
  isLocal?: boolean;
  isToDelete: boolean;
}

interface ITask0 {
  order: number;
  task: ITask;
}

interface ITask {
  uuid: string; //task
  order: number;
  isToDelete: boolean;
  task_files: Array<ITaskFile> | null;
  activities: Array<IActivity>;
}


interface ITaskFile {
  uuid: string;
  order: number;
  file: IFile;
  textrtf: Delta | null;
  isText: boolean
}

interface IActivity {
  uuid: string;
  //FK_task: string,
  order: number;
  activity_type: number; // 1-problem, 2-tutorial,
  tutorial: ITutorial | undefined;
  problem: IProblem | undefined;
  isToDelete: boolean;
  isCorrected: boolean;
}

interface ITutorial {
  uuid: string;
  tutorialFiles: Array<ITutorialFile> | null;
}
interface ITutorialFile {
  uuid: string;
  file: IFile;
  order: number;
  textrtf: Delta | null;
}

enum ProblemType {
  OPEN = "open",
  TRUE_FALSE = "true-false",
  MULTIPLE_CHOICES = "multiple-choices",
  MATCH_CASE = "match-case",
  SHORT_ANSWER = "short-answer",
}

interface IProblem {
  uuid: string;
  problem_type: ProblemType; //open, multiple-choices, true-false
  max_points: number;
  teacher_solution: Array<ISolution>; // Instruction[0], Video/Media Solutions[1] Section - in WKS Creation ??
  //
  open_ended: IOpenEnded | undefined;
  close_ended: IClosedEnded | undefined | null;
}

interface ISolution {
  uuid: string;
  order: number;
  isToDelete: boolean;
  solution_files: Array<IFileGroup | null>; // solution_files[0] -> Instruction, solution_files[1...n] -> Video/Media Solutions
}

interface IClosedEnded {
  uuid: string;
  n_options: number;
  correct_answer: string;
  correct_answer_pos: number | null; // starting at 0
}
interface IOpenEnded {
  uuid: string;
  photo: boolean;
  video: boolean;
  audio: boolean;
  text: boolean;
  pdf: boolean;
  document: boolean;
  max_num_files: number;
  max_size_mbytes: number;
  evaluation_scale: EvaluationScale;
}
interface IAnswerType {
  photo: boolean;
  video: boolean;
  audio: boolean;
  text: boolean;
  document: boolean;
  pdf: boolean;
  max_num_files: number;
  max_size_mbytes: number;
}

interface IWorksheetCreationMetaData {
  //Novo
  course: string;
  qualitative_assessment_levels: string;
  max_num_files_question: number;
  max_num_files_solution: number;
  max_file_size_mbytes_question: number;
  open_answer_options: number[];
  max_duration_audio_question_seconds: number;
  max_duration_video_question_seconds: number;
  is_video_mandatory: boolean;
  skills: string[];
  isPage1Changed: boolean;
  isPage2Changed: boolean;
  keyboard: number;

  //ANTIGOOO

  number_options_multiple_choice: number;
  multiple_choice_options: number[];
  uuid: string;
}

const useWorksheetCreationStore = defineStore("worksheetCreationStore", () => {
  const worksheet = ref<IWorksheet>({
    uuid: "",
    selectedTaskUUID: "",
    selectedActivityUUID: "",
    name: "",
    difficulty_level: 1,
    difficulty_level_str: "",
    wks_type: 1,
    wks_type_str: "",
    extra_info: null,
    learning_scenario: null,
    creation_status: 0,
    comment: "",
    is_ok: null,
    author: "",
    tasks: [
      {
        // uuid: '1', //task
        order: 1,
        task: {
          uuid: "", // 1 with only 1 activity
          order: 1,
          task_files: [
            {
              uuid: "",
              order: 1,
              file: {
                filename: "",
                filetype: "",
                filepath: "",
                isToUpdate: false,
                isToDelete: false,
              },
              textrtf: null,
              isText: false,
            },
          ],

          // https://milage.ualg.pt/milv2media/documents/pt/pt99/c0685t5115w00009482q00023477.pdf
          activities: [
            {
              uuid: "1",
              order: 1,
              activity_type: 2, // 1-problem, 2-tutorial,
              isCorrected: false,
              //FK_task: "",
              tutorial: {
                uuid: "",
                tutorialFiles: [
                  {
                    uuid: "",
                    order: 1,
                    file: {
                      filename: "",
                      filetype: "",
                      filepath: "",
                      isToUpdate: false,
                      isToDelete: false,
                    },
                    textrtf: null,
                  },
                ],
              },
              problem: undefined,
              isToDelete: false,
            },
          ],
          isToDelete: false,
        },
      },
    ],
  });

  const uuid = ref<string>("");

  const metaData = ref<IWorksheetCreationMetaData>({
    isPage1Changed: false, isPage2Changed: false,
    course: "",
    qualitative_assessment_levels: "",
    max_num_files_question: 0,
    max_num_files_solution: 0,
    max_file_size_mbytes_question: 0,
    open_answer_options: [],
    max_duration_audio_question_seconds: 0,
    max_duration_video_question_seconds: 0,
    is_video_mandatory: false,
    skills: [],
    number_options_multiple_choice: 0,
    multiple_choice_options: [],
    uuid: "",
    keyboard: 0
  });

  const isNew = ref<boolean>(false);
  const isFormulaEditor = ref<boolean>(false);

  const addTaskText = async () => {
    if (currentTask0.value) {
      console.log("passou no if");

      const taskWrapper: ITask0 = currentTask0.value;
      const task: ITask = taskWrapper.task;

      if (!task) {
        console.error("Task not found in currentTask0.");
        return;
      }

      if (!Array.isArray(task.task_files)) {
        task.task_files = [];
      }

      const newTaskFile: ITaskFile = {
        uuid: nanoid(),
        order: 1,
        file: {
          isToDelete: false,
          filename: "",
          filetype: "",
          filepath: "",
          isToUpdate: true,
        },
        textrtf: {} as Delta,
        isText: true,
      };

      console.log("Antes de adicionar:", task.task_files);

      task.task_files.unshift(newTaskFile);

      console.log("Depois de adicionar:", task.task_files);

      task.task_files.forEach((tf, index) => {
        tf.order = index + 1;
      });

      metaData.value.isPage2Changed = true;
    } else {
      console.error("No current task to add task text.");
    }
  };


  const deleteTaskText = async () => {
    if (selectedTaskIndex.value !== undefined && selectedTaskIndex.value !== null) {
      const taskIndex = selectedTaskIndex.value;
      const taskWrapper: ITask0 = worksheet.value.tasks[taskIndex]
      const task = taskWrapper.task;

      if (!task) {
        return;
      }
      if (task.task_files && task.task_files.length > 0) {
        const validTask = task.task_files.find((taskFile) => !taskFile.file.isToDelete)

        if (validTask) {
          validTask.textrtf = null
          validTask.file.isToDelete = true
          validTask.file.isToUpdate = false
        }

      }
      metaData.value.isPage2Changed = true;
    } else {
      console.error("No selected task index to delete task text.");
    }
  };

  const addSolutionFile = async () => {
    try {
      if (
        currentActivity.value &&
        currentActivity.value.problem
      ) {
        const problem = currentActivity.value.problem;

        if (!problem.teacher_solution || problem.teacher_solution.length === 0) {
          problem.teacher_solution = [];
        }


        const newSolutionFile: IFileGroup = {
          uuid: "",
          order: 1,
          file: {
            filename: "",
            filepath: "",
            filetype: "",
            isToUpdate: true,
            isToDelete: false,
          },
          textrtf: new Delta(),
        };

        if (problem.teacher_solution.length === 0) {
          const newTeacherSolution: ISolution = {
            uuid: nanoid(),
            order: 1,
            solution_files: [newSolutionFile],
            isToDelete: false,
          };
          problem.teacher_solution.unshift(newTeacherSolution);
        } else {
          const firstTeacherSolution = problem.teacher_solution[0];
          firstTeacherSolution.solution_files.unshift(newSolutionFile);
          firstTeacherSolution.solution_files.forEach((sf, index) => {
            if (sf) {
              sf.order = index + 1;
            } else {
              console.warn(`solution_file no índice ${index} é null e foi ignorado.`);
            }
          });

        }

        metaData.value.isPage2Changed = true;

        console.log("Novo solution_file adicionado no início da lista.");
      } else {
        console.error("Atividade atual ou problema não definidos.");
      }
    } catch (error) {
      console.error("Erro ao adicionar um novo solution_file:", error);
    }
  };

  const addTutorialFile = async () => {
    try {
      if (
        currentActivity.value &&
        currentActivity.value.tutorial
      ) {
        const tutorial = currentActivity.value.tutorial;
        if (!tutorial.tutorialFiles) {
          tutorial.tutorialFiles = [];
        }
        const newTutorialFile: ITutorialFile = {
          uuid: "",
          order: 1,
          file: {
            filename: "",
            filepath: "",
            filetype: "",
            isToUpdate: true,
            isToDelete: false,
          },
          textrtf: new Delta(),
        };

        tutorial.tutorialFiles.unshift(newTutorialFile);
        tutorial.tutorialFiles.forEach((tFile, index) => {
          tFile.order = index + 1;
        });
        metaData.value.isPage2Changed = true;
      } else {
        console.error("Atividade atual ou tutorial não definidos.");
      }
    } catch (error) {
      console.error("Erro ao adicionar um novo tutorialFile:", error);
    }
  };

  const deleteSolutionTextRef = async () => {
    if (!currentActivity.value) {
      console.error("Não existe atividade selecionada para remover o texto.");
      return;
    }

    const problem = currentActivity.value?.problem;
    if (!problem) {
      console.error("A atividade selecionada não possui 'problem'.");
      return;
    }
    if (
      !problem.teacher_solution ||
      problem.teacher_solution.length === 0 ||
      !problem.teacher_solution[0].solution_files ||
      problem.teacher_solution[0].solution_files.length === 0
    ) {
      console.error("Não foram encontradas solution_files para apagar o textRef.");
      return;
    }
    const firstSolutionFile = problem.teacher_solution[0].solution_files[0];
    if (firstSolutionFile) {
      firstSolutionFile.textrtf = null;
      firstSolutionFile.file.isToDelete = true;
      firstSolutionFile.file.isToUpdate = false;
    }
    console.log("Texto do teacher_solution removido com sucesso!");
  };

  const deleteTutorialTextRef = async () => {
    if (!currentActivity.value) {
      console.error("Não existe atividade selecionada para remover o texto.");
      return;
    }

    const tutorial = currentActivity.value?.tutorial;
    if (!tutorial) {
      console.error("A atividade selecionada não possui 'problem'.");
      return;
    }
    if (
      !tutorial.tutorialFiles ||
      tutorial.tutorialFiles.length === 0 ||
      !tutorial.tutorialFiles[0]
    ) {
      console.error("Não foram encontradas solution_files para apagar o textRef.");
      return;
    }
    const firstSolutionFile = tutorial.tutorialFiles[0];
    if (firstSolutionFile) {
      firstSolutionFile.textrtf = null;
      firstSolutionFile.file.isToDelete = true;
      firstSolutionFile.file.isToUpdate = false;
    }
    console.log("Texto do teacher_solution removido com sucesso!");
  };

  const addTask = async () => {
    const task: ITask = {
      order: 1,
      uuid: nanoid(),
      task_files: [],
      activities: [],
      isToDelete: false,
    };
    const task0: ITask0 = {
      order: activeTasks.value.length + 1,
      task: task,
    };

    if (!worksheet.value.tasks) {
      worksheet.value.tasks = [];
    }
    worksheet.value.tasks.push(task0);
    metaData.value.isPage2Changed = true;
    await selectTaskIndex(activeTasks.value.length - 1);
    const remainingTasks = worksheet.value.tasks.filter(
      (t) => !t.task.isToDelete
    );
    remainingTasks.forEach((t, idx) => {
      t.order = idx + 1;
    });
    await addActivity();
  };

  const addTaskFakeUUID = async () => {
    const task: ITask = {
      order: 1,
      uuid: generateUUID(),
      task_files: [],
      activities: [],
      isToDelete: false,
    };
    const task0: ITask0 = {
      order: activeTasks.value.length + 1,
      task: task,
    };

    if (!worksheet.value.tasks) {
      worksheet.value.tasks = [];
    }
    worksheet.value.tasks.push(task0);

    await selectTaskIndex(activeTasks.value.length - 1);
    const remainingTasks = worksheet.value.tasks.filter(
      (t) => !t.task.isToDelete
    );
    remainingTasks.forEach((t, idx) => {
      t.order = idx + 1;
    });
    await addActivityFakeUUID();
  };

  // **** Activity Index Functions ****
  const selectedActivityIndex = ref<number | undefined>(undefined);

  async function selectActivityIndex(newIndex: number) {
    console.log("new selected activity index: ", newIndex);
    const oldIndex = selectedActivityIndex.value;
    console.log("old selected activity index: ", oldIndex);
    if (oldIndex === newIndex || !worksheet.value) return;
    spinnerManager.registerTask("selectActivityIndex");

    if (selectedTaskIndex.value === undefined) {
      watchOnce(selectedTaskIndex, async (newTaskIndex, oldTaskIndex) => {
        console.warn("watch once has been triggered!");
        if (newTaskIndex !== undefined) {
          if (oldTaskIndex !== undefined) {
            const newActivity =
              worksheet.value.tasks[newTaskIndex].task.activities.at(newIndex);
            const oldActivity =
              worksheet.value.tasks[newTaskIndex].task.activities.at(
                oldTaskIndex
              );

            if (newActivity) await memoryManager.load(newActivity);
            selectedActivityIndex.value = newIndex;
            if (oldActivity) await memoryManager.save(oldActivity);
          } else {
            const newActivity =
              worksheet.value.tasks[newTaskIndex].task.activities[newIndex];
            await memoryManager.load(newActivity);
            selectedActivityIndex.value = newIndex;
          }
        }
      });
    } else {
      const activities = activeActivities.value.filter(
        (activity) => !activity.isToDelete
      );
      console.log("activities: ", activities);

      if (activities) {
        const newActivity = activities.at(newIndex);

        console.log("old activity index: ", oldIndex);
        if (oldIndex)
          console.log(
            "old activity at index oldIndex: ",
            activities.at(oldIndex)
          );

        console.log("loading new activity: ", newActivity);
        if (newActivity) await memoryManager.load(newActivity);

        selectedActivityIndex.value = newIndex;

        console.log("old activity: ", activities[oldIndex!]);
      }
    }
    spinnerManager.unregisterTask("selectActivityIndex");
  }
  // *****************************

  // **** Task Index Functions ****
  const selectedTaskIndex = ref<number | undefined>(undefined); // As the server responds with empty tasks, we might need to set this to undefined or -1 to indicate no existence
  async function selectTaskIndex(index: number | undefined) {
    if (index !== selectedTaskIndex.value) {
      await selectActivityIndex(0);
    }
    selectedTaskIndex.value = index;
  }

  if (worksheet.value.tasks.length > 0) {
    selectTaskIndex(0);
  } else {
    addTask();
    // selectTaskIndex(undefined);
  }
  // *****************************

  const addActivity = async () => {
    if (!worksheet.value.tasks) {
      return;
    }

    // problem is selected by default
    let isProblem = true;
    if (
      typeof selectedTaskIndex.value === "number" &&
      typeof selectedActivityIndex.value === "number" &&
      worksheet.value.tasks[selectedTaskIndex.value].task.activities.length >
      selectedActivityIndex.value
    ) {
      isProblem =
        worksheet.value.tasks[selectedTaskIndex.value].task.activities[
          selectedActivityIndex.value
        ].problem !== undefined;
    }

    const activity: IActivity = {
      uuid: "",
      order:
        selectedTaskIndex.value !== undefined
          ? activeTasks.value[selectedTaskIndex.value].task.activities.length +
          1
          : 1,
      activity_type: 2, // 1-problem, 2-tutorial,
      tutorial: {
        uuid: "",
        tutorialFiles: null,
      },
      problem: undefined,
      isToDelete: false,
      isCorrected: false,
    };

    // dependent on the previous activity type, we set the new activity type equal to the previous one
    if (isProblem) {
      activityHelpers.changeActivityType(activity, 1, metaData.value);
    } else {
      activityHelpers.changeActivityType(activity, 2, metaData.value);
    }

    if (typeof selectedTaskIndex.value === "number") {
      activeTasks.value[selectedTaskIndex.value].task.activities.push(activity);
      await selectActivityIndex(activeActivities.value.length - 1);
    } else {
      worksheet.value.tasks.push({
        order: 0,
        task: {
          uuid: "",
          order: 1,
          task_files: [],
          activities: [activity],
          isToDelete: false,
        },
      });
    }

    const remainingTasks = worksheet.value.tasks[
      selectedTaskIndex.value!
    ].task.activities.filter((t) => !t.isToDelete);
    remainingTasks.forEach((a, idx) => {
      a.order = idx + 1;
    });

    metaData.value.isPage2Changed = true;
  };

  function generateUUID() {
    return (
      "fake-" +
      Math.random().toString(36).substring(2, 11) +
      "-" +
      Math.random().toString(36).substring(2, 11)
    );
  }

  const addActivityFakeUUID = async () => {
    if (!worksheet.value.tasks) {
      return;
    }

    // problem is selected by default
    let isProblem = true;
    if (
      typeof selectedTaskIndex.value === "number" &&
      typeof selectedActivityIndex.value === "number" &&
      worksheet.value.tasks[selectedTaskIndex.value].task.activities.length >
      selectedActivityIndex.value
    ) {
      isProblem =
        worksheet.value.tasks[selectedTaskIndex.value].task.activities[
          selectedActivityIndex.value
        ].problem !== undefined;
    }

    const activity: IActivity = {
      uuid: generateUUID(),
      order:
        selectedTaskIndex.value !== undefined
          ? activeTasks.value[selectedTaskIndex.value].task.activities.length +
          1
          : 1,
      activity_type: 2, // 1-problem, 2-tutorial,
      tutorial: {
        uuid: "",
        tutorialFiles: null,
      },
      problem: undefined,
      isToDelete: false,
      isCorrected: false,
    };

    // dependent on the previous activity type, we set the new activity type equal to the previous one
    if (isProblem) {
      activityHelpers.changeActivityType(activity, 1, metaData.value);
    } else {
      activityHelpers.changeActivityType(activity, 2, metaData.value);
    }

    if (typeof selectedTaskIndex.value === "number") {
      activeTasks.value[selectedTaskIndex.value].task.activities.push(activity);
      await selectActivityIndex(activeActivities.value.length - 1);
    } else {
      worksheet.value.tasks.push({
        order: 0,
        task: {
          uuid: "",
          order: 1,
          task_files: [],
          activities: [activity],
          isToDelete: false,
        },
      });
    }

    const remainingTasks = worksheet.value.tasks[
      selectedTaskIndex.value!
    ].task.activities.filter((t) => !t.isToDelete);
    remainingTasks.forEach((a, idx) => {
      a.order = idx + 1;
    });
  };

  // const deleteSelectedActivity = async () => {
  //   // If there is only one activity, don't delete it or if there is or no activity or no task
  //   if (
  //     selectedActivityIndex.value === undefined ||
  //     selectedTaskIndex.value === undefined ||
  //     worksheet.value.tasks[selectedTaskIndex.value].task.activities.length ===
  //     1
  //   )
  //     return;

  //   worksheet.value.tasks![selectedTaskIndex.value].task.activities.splice(
  //     selectedActivityIndex.value,
  //     1
  //   );

  //   await selectActivityIndex(0);
  //   worksheet.value.tasks[selectedTaskIndex.value].task.activities.forEach(
  //     (a, idx) => {
  //       a.order = idx + 1;
  //     }
  //   );
  // };

  // const deleteSelectedTask = async () => {
  //   if (
  //     selectedTaskIndex.value === undefined ||
  //     worksheet.value.tasks.length === 1
  //   )
  //     return;

  //   await selectTaskIndex(0);
  //   await selectActivityIndex(0);
  //   worksheet.value.tasks!.splice(selectedTaskIndex.value, 1);
  //   worksheet.value.tasks.forEach((t, idx) => {
  //     t.order = idx + 1;
  //   });
  // };

  const markSelectedTaskToDelete = async () => {
    if (
      selectedTaskIndex.value === undefined ||
      worksheet.value.tasks.length === 1 ||
      activeTasks.value.length === 1
    ) {
      if (activeTasks.value.length === 1) {
        if (selectedTaskIndex.value === 0) {
          const singleTask = activeTasks.value[selectedTaskIndex.value];
          if (singleTask.task.task_files && singleTask.task.task_files[0]) {
            singleTask.task.task_files[0].file.isToDelete = true;
            singleTask.task.task_files[0].file.isToUpdate = false;
          }
          singleTask.task.activities.forEach((activity) => {
            activity.order = -1;
            activity.isToDelete = true;
          });
          console.log("ORIGINAL", singleTask)
          await addActivity();
          worksheet.value.tasks = [...worksheet.value.tasks];
        }
      }
      return;
    }

    const currentTask0 = activeTasks.value[selectedTaskIndex.value];
    if (!currentTask0) {
      console.error(
        "No task found to delete at index:",
        selectedTaskIndex.value
      );
      return;
    }

    const newIndex =
      selectedTaskIndex.value === 0
        ? selectedTaskIndex.value
        : selectedTaskIndex.value - 1;

    const originalTaskIndex = worksheet.value.tasks.findIndex(
      (t) => t.order === currentTask0.order
    );
    console.log("ORIGINAL", originalTaskIndex)
    console.log("ORIGINAL 1", worksheet.value.tasks[originalTaskIndex], worksheet.value.tasks[originalTaskIndex].order, worksheet.value.tasks[originalTaskIndex].task.activities)
    if (originalTaskIndex !== -1) {


      worksheet.value.tasks[originalTaskIndex].task.isToDelete = true;
      worksheet.value.tasks[originalTaskIndex].order = -1;
      worksheet.value.tasks[originalTaskIndex].task.activities.forEach(
        (activity) => {
          activity.isToDelete = true;
          activity.order = -1;
        }
      );
      worksheet.value.tasks[originalTaskIndex]?.task.task_files?.forEach(
        (task_file) => {
          if (task_file?.file) {
            task_file.file.isToDelete = true;
            task_file.file.isToUpdate = false;
            task_file.order = -1;
          }
        }
      );
      await selectTaskIndex(newIndex);
      await selectActivityIndex(0);
      worksheet.value.tasks = [...worksheet.value.tasks];

      const remainingTasks = worksheet.value.tasks.filter(
        (t) => !t.task.isToDelete
      );
      remainingTasks.forEach((task, index) => {
        task.order = index + 1;
      });
    } else {
      console.error("Failed to find task in the original list");
    }
  };

  const markSelectedActivityToDelete = async () => {
    if (
      selectedActivityIndex.value === undefined ||
      selectedTaskIndex.value === undefined ||
      activeActivities.value.length === 1
    ) {
      if (activeActivities.value.length === 1) {
        activeActivities.value[0].order = -1;
        activeActivities.value[0].isToDelete = true;
        await addActivity();
      }
      return;
    }

    const currentActivity = activeActivities.value[selectedActivityIndex.value];

    if (!currentActivity) {
      console.error(
        "No activity found to delete at index:",
        selectedActivityIndex.value
      );
      return;
    }
    const newIndex =
      selectedActivityIndex.value === 0
        ? selectedActivityIndex.value
        : selectedActivityIndex.value - 1;

    const task = worksheet.value.tasks[selectedTaskIndex.value];
    const originalActivityIndex = task.task.activities.findIndex(
      (a) => a.order === currentActivity.order
    );
    if (originalActivityIndex !== -1) {
      task.task.activities[originalActivityIndex].isToDelete = true;
      task.task.activities[originalActivityIndex].order = -1;
      console.log(
        "Activity marked for deletion",
        task.task.activities[originalActivityIndex].isToDelete,
        currentActivity.order
      );
      const remainingActivities = task.task.activities
        .filter((a) => !a.isToDelete)
        .sort((a1, a2) => a1.order - a2.order);

      remainingActivities.forEach((activity, index) => {
        activity.order = index + 1;
      });

      task.task.activities = [...remainingActivities];

      await selectActivityIndex(newIndex);
    } else {
      console.error("Failed to find activity in the original list");
    }
  };

  const init = async (
    name: string,
    uuid: string,
    difficulty: number,
    wks_type: number,
    extra_info_file: IFile | null,
    learning_scenario_file: IFile | null
  ) => {
    const taskUUID = nanoid();
    const activityUUID = nanoid();

    worksheet.value = {
      uuid: uuid,
      selectedTaskUUID: taskUUID,
      selectedActivityUUID: activityUUID,
      name: name,
      difficulty_level: difficulty,
      difficulty_level_str: "",
      wks_type: wks_type,
      wks_type_str: "",
      extra_info: null,
      learning_scenario: null, /// (Somewhere in the past) learning_scenario can at the moment not be null. This should be fixed or the prev. page should force learning scenario -> 24.06.24 Seems Fixed
      creation_status: 0,
      comment: "",
      is_ok: null,
      author: "",
      tasks: [
        {
          // uuid: '1', //task
          order: 1,
          task: {
            order: 1,
            uuid: taskUUID, // 1 with only 1 activity
            task_files: [
              // {
              //   uuid: taskUUID,
              //   order: 1,
              //   file: { filename: "", filetype: "", filepath: "" }, // TODO: this can be null during creation
              // },
            ],
            // https://milage.ualg.pt/milv2media/documents/pt/pt99/c0685t5115w00009482q00023477.pdf
            activities: [
              {
                uuid: activityUUID,
                order: 1,
                activity_type: 1, // 1-problem, 2-tutorial,
                isCorrected: false,
                //FK_task: "",
                tutorial: undefined,
                problem: {
                  uuid: "",
                  problem_type: ProblemType.OPEN, //open, multiple-choices, true-false
                  max_points: NaN,
                  teacher_solution: [],
                  open_ended: {
                    uuid: "",
                    photo: false,
                    video: false,
                    audio: false,
                    text: false,
                    pdf: false,
                    document: false,
                    max_num_files: metaData.value?.max_num_files_solution ?? 0,
                    max_size_mbytes:
                      metaData.value?.max_file_size_mbytes_question ?? 0,
                    evaluation_scale: EvaluationScale.QUANTITATIVE,
                  },
                  close_ended: undefined,
                },
                isToDelete: false,
              },
            ],
            isToDelete: false,
          },
        },
      ],
    };

    if (extra_info_file) {
      worksheet.value.extra_info = {
        filename: extra_info_file.filename,
        filetype: extra_info_file.filetype,
        filepath: extra_info_file.filepath,
        isToUpdate: false,
        isToDelete: false,
      };
    }
    if (learning_scenario_file) {
      worksheet.value.learning_scenario = {
        filename: learning_scenario_file.filename,
        filetype: learning_scenario_file.filetype,
        filepath: learning_scenario_file.filepath,
        isToUpdate: false,
        isToDelete: false,
      };
    }

    await selectTaskIndex(0);
    await selectActivityIndex(0);
  };

  const getCurrentActivities = computed<IActivity[]>(() => {
    if (currentTask0.value && currentTask0.value.task.activities) {
      return currentTask0.value.task.activities;
    }
    return [];
  });

  const getCurrentTasks = computed<ITask0[]>(() => {
    if (
      selectedTaskIndex.value === undefined ||
      worksheet.value.tasks.length === 0
    ) {
      return [];
    } else {
      return worksheet.value.tasks;
    }
  });

  const activeTasks = computed<ITask0[]>(() => {
    return getCurrentTasks.value.filter(
      (taskWrapper) => !taskWrapper.task.isToDelete
    );
  });

  const activeActivities = computed(() => {
    return getCurrentActivities.value.filter(
      (activity) => !activity.isToDelete
    );
  });

  const currentActivity = computed<IActivity | undefined>(() => {
    const activities = activeActivities.value;

    if (typeof selectedActivityIndex.value === "number") {
      const currentIdx = selectedActivityIndex.value;

      if (currentIdx >= 0 && currentIdx < activities.length) {
        return activities[currentIdx];
      }
    }

    if (activities.length > 0) {
      selectActivityIndex(0)
      return activities[0];
    }

    return undefined;
  });

  const currentTaskFather = computed<ITask | undefined>(() => {
    if (
      selectedTaskIndex.value === undefined ||
      !activeTasks.value ||
      !activeTasks.value[selectedTaskIndex.value]
    ) {
      return undefined;
    }
    return activeTasks.value[selectedTaskIndex.value].task;
  });

  const currentTask0 = computed<ITask0 | undefined>(() => {
    if (
      selectedTaskIndex.value === undefined ||
      selectedTaskIndex.value < 0 ||
      selectedTaskIndex.value >= worksheet.value.tasks.length
    ) {
      return undefined;
    }
    return activeTasks.value[selectedTaskIndex.value];
  });

  const currentTask = computed<ITask | undefined>(() => {
    if (
      selectedTaskIndex.value === undefined ||
      selectedTaskIndex.value < 0 ||
      selectedTaskIndex.value >= activeTasks.value.length
    ) {
      if (selectedTaskIndex.value && selectedTaskIndex.value >= activeTasks.value.length) {
        return activeTasks.value[0].task;
      }
      return undefined;
    }
    return activeTasks.value[selectedTaskIndex.value].task;
  });


  const selectLastActivity = async () => {
    await selectActivityIndex(getCurrentActivities.value.length - 1);
  };
  //TODO: Set user-worksheets-solve-assess-download - uuid: string somehow somewhere

  // LOAD EXISTING WORKSHEET INTO EDITOR STORE
  const learnFilterData = useLearnUserFiltersStore();
  async function loadWorksheet(argUuid: string) {
    const courseUUID = learnFilterData.filters[0];
    if (typeof courseUUID === "string") {
      const data =
        await requestService.getWithParams<IWorksheetCreationMetaData>(
          "create-update-worksheet",
          {
            course: courseUUID,
          }
        );
      metaData.value = data.data;
      metaData.value.isPage1Changed = false;
      metaData.value.isPage2Changed = false;
    } else {
      throw new Error(
        "Course UUID is not a string or not present. Can not load worksheet meta"
      );
    }

    const params = { uuid: argUuid };
    const resp = await requestService.getWithParams("worksheet", params);

    if (!(200 <= resp.status && resp.status < 300)) throw resp;
    uuid.value = argUuid;
    const data = resp.data as IWorksheet;
    await setWksData(data);
  }

  // async function loadWorksheet2(argUuid: string) {
  //   const courseUUID = learnFilterData.filters[0];
  //   if (typeof courseUUID === "string") {
  //     const data =
  //       await requestService.getWithParams<IWorksheetCreationMetaData>(
  //         "create-worksheet",
  //         {
  //           course: courseUUID,
  //         }
  //       );
  //     metaData.value = data.data;
  //   } else {
  //     throw new Error(
  //       "Course UUID is not a string or not present. Can not load worksheet meta"
  //     );
  //   }

  //   const params = { uuid: argUuid };
  //   const resp = await requestService.getWithParams("worksheet", params);
  //   if (!(200 <= resp.status && resp.status < 300)) throw resp;
  //   uuid.value = argUuid;
  //   const data = resp.data as IWorksheet;
  //   await setWksData(data);
  // }

  async function setWksData(data: IWorksheet) {
    isNew.value = false;
    worksheet.value = data;
    console.log("WORKSHEET", worksheet.value);
    if (data.tasks.length > 0) {
      await selectTaskIndex(0);
      await selectActivityIndex(0);
    } else {
      await addTask();
    }
  }

  function setWksInfo(params: {
    wkstype: string;
    difficulty: string;
    name: string;
    latitude: string;
    longitude: string;
    extraInfo: IFile | null;
    learningScenario: IFile | null;
  }) {
    const {
      wkstype,
      difficulty,
      name,
      latitude,
      longitude,
      extraInfo,
      learningScenario,
    } = params;

    worksheet.value.name = name;
    worksheet.value.difficulty_level = parseInt(difficulty);
    worksheet.value.wks_type = parseInt(wkstype);

    if (extraInfo) {
      worksheet.value.extra_info = {
        filename: extraInfo.filename,
        filetype: extraInfo.filetype,
        filepath: extraInfo.filepath,
        isToUpdate: false,
        isToDelete: false,
      };
    } else {
      worksheet.value.extra_info = null;
    }

    if (learningScenario) {
      worksheet.value.learning_scenario = {
        filename: learningScenario.filename,
        filetype: learningScenario.filetype,
        filepath: learningScenario.filepath,
        isToUpdate: false,
        isToDelete: false,
      };
    } else {
      worksheet.value.learning_scenario = null;
    }
  }

  function clearMemory() {
    worksheet.value.tasks.forEach((task) => {
      task.task.activities.forEach((activity) => {
        memoryManager.clear();
      });
    });
  }

  async function deleteWorksheet() {
    const courseUUID = learnFilterData.filters[0];
    clearMemory();
    return await requestService.post<{ router: string }>("delete-worksheet", {
      course: courseUUID,
      worksheet: worksheet.value.uuid,
    });
  }

  function updateTask(task: ITask, tsk: ITask) {
    task.uuid = tsk.uuid;
    task.isToDelete = tsk.isToDelete;
    task.order = tsk.order;
  }

  function updateTaskFiles(taskFile: ITaskFile, tskFile: ITaskFile) {
    taskFile.uuid = tskFile.uuid;
    taskFile.file = tskFile.file;
    taskFile.order = tskFile.order;
    taskFile.textrtf = tskFile.textrtf
    const cacheKey = `${taskFile.file.filepath}-${taskFile.file.filename}`;
    removeBlob(cacheKey);
  }

  function updateActivity(activity: IActivity, act: IActivity) {
    activity.uuid = act.uuid;
    activity.isToDelete = act.isToDelete;
    activity.order = act.order;
  }

  function updateProblem(problem: IProblem, prob: IProblem) {
    problem.uuid = prob.uuid;
    problem.close_ended = prob.close_ended;
    problem.open_ended = prob.open_ended;
    problem.max_points = prob.max_points;
  }

  function updateTutorial(tutorial: ITutorial, tut: ITutorial) {
    tutorial.uuid = tut.uuid;
  }

  function updateTutorialFile(
    tutorialFile: ITutorialFile,
    tutFile: ITutorialFile
  ) {
    tutorialFile.uuid = tutFile.uuid;
    tutorialFile.file = tutFile.file;
    tutorialFile.order = tutFile.order;
    tutorialFile.textrtf = tutFile.textrtf
    const cacheKey = `${tutorialFile.file.filepath}-${tutorialFile.file.filename}`;
    removeBlob(cacheKey);
  }

  function updateSolution(solution: ISolution, solu: ISolution) {
    solution.uuid = solu.uuid;
    solution.order = solu.order;
    solution.isToDelete = solu.isToDelete;
  }

  function updateSolutionFile(solutionFile: IFileGroup, soluFile: IFileGroup) {
    solutionFile.uuid = soluFile.uuid;
    solutionFile.order = soluFile.order;
    solutionFile.file = soluFile.file;
    solutionFile.textrtf = soluFile.textrtf
    const cacheKey = `${solutionFile.file.filepath}-${solutionFile.file.filename}`;
    removeBlob(cacheKey);
  }

  const cleanUpStore = () => {
    worksheet.value.tasks = worksheet.value.tasks.filter((taskWrapper) => {
      const task = taskWrapper.task;

      if (task.isToDelete) return false;

      if (task.task_files) {
        task.task_files = task.task_files.filter(
          (taskFile) => !taskFile.file.isToDelete
        );
      }
      if (task.activities) {
        task.activities = task.activities.filter(
          (activity) => !activity.isToDelete
        );
        task.activities.forEach((activity) => {
          if (activity.activity_type === 1 && activity.problem) {
            activity.problem.teacher_solution =
              activity.problem.teacher_solution.filter(
                (solution) => !solution.isToDelete
              );
            activity.problem.teacher_solution.forEach((solution) => {
              solution.solution_files = solution.solution_files.filter(
                (solution_file) =>
                  solution_file && !solution_file.file.isToDelete
              );
            });
          }

          if (
            activity.activity_type === 2 &&
            activity.tutorial &&
            activity.tutorial.tutorialFiles
          ) {
            activity.tutorial.tutorialFiles =
              activity.tutorial.tutorialFiles.filter(
                (tutorialFile) => !tutorialFile.file.isToDelete
              );
          }
        });
      }

      return true;
    });
  };

  return {
    worksheet,
    // deleteSelectedActivity,
    addActivityFakeUUID,
    addActivity,
    addTask,
    addTaskText,
    addSolutionFile,
    addTutorialFile,
    deleteTaskText,
    deleteSolutionTextRef,
    deleteTutorialTextRef,
    // deleteSelectedTask,
    init,
    currentActivity,
    currentTask0,
    selectTaskIndex,
    selectedTaskIndex,
    selectActivityIndex,
    selectedActivityIndex,
    getCurrentActivities,
    metaData,
    serializeTasks,
    loadWorksheet,
    deleteWorksheet,
    setWksInfo,
    setWksData,
    uuid,
    markSelectedActivityToDelete,
    markSelectedTaskToDelete,
    activeActivities,
    getCurrentTasks,
    activeTasks,
    currentTask,
    currentTaskFather,
    updateTask,
    updateTaskFiles,
    updateActivity,
    updateProblem,
    updateTutorial,
    updateTutorialFile,
    updateSolution,
    updateSolutionFile,
    clearMemory,
    cleanUpStore,
    addTaskFakeUUID,
    isNew,
    isFormulaEditor,
  };
});

const problemHelpers = {
  changeProblemType(
    currentActivity: IActivity,
    type: ProblemType,
    meta?: IWorksheetCreationMetaData
  ) {
    if (currentActivity.problem) {
      if (currentActivity.problem.problem_type === type) {
        return;
      }

      currentActivity.problem.problem_type = type;
      if (type === ProblemType.OPEN) {
        currentActivity.problem.open_ended = {
          uuid: "",
          photo: false,
          video: false,
          audio: false,
          text: false,
          pdf: false,
          document: false,
          max_num_files: meta?.max_num_files_solution ?? 0,
          max_size_mbytes: meta?.max_file_size_mbytes_question ?? 0,
          evaluation_scale: EvaluationScale.QUANTITATIVE,
        };
        currentActivity.problem.close_ended = undefined;
      } else {
        let nOptions = 0;
        if (type === ProblemType.MULTIPLE_CHOICES) {
          nOptions = meta?.number_options_multiple_choice ?? 0;
        } else {
          nOptions = 2;
        }
        currentActivity.problem.close_ended = {
          uuid: "",
          n_options: nOptions,
          correct_answer: "", // not being taken into account (only for compatibility reasons)
          correct_answer_pos: null,
        };
        currentActivity.problem.open_ended = undefined;
      }
    }
  },
  getAnswer(
    currentActivity: IActivity
  ): IOpenEnded | IClosedEnded | undefined | null {
    if (currentActivity.problem?.problem_type) {
      if (currentActivity.problem.problem_type === ProblemType.OPEN)
        return currentActivity.problem.open_ended;
      // (currentActivity.problem.problem_type === ProblemType.TRUE_FALSE || currentActivity.problem.problem_type === ProblemType.MULTIPLE_CHOICES)
      else return currentActivity.problem.close_ended;
    } else return undefined;
  },
  toggleProblemType(currentActivity: IActivity, type: ProblemType) {
    if (currentActivity.problem) {
      currentActivity.problem.problem_type = type;

      if (type === ProblemType.TRUE_FALSE) {
        currentActivity.problem.close_ended;
      }
    }
  },
  setOpenAnswerOptions(options: boolean[], currentActivity: IActivity) {
    if (currentActivity.problem) {
      const openEnded = currentActivity.problem.open_ended;
      if (openEnded) {
        openEnded.text = options[0];
        openEnded.photo = options[1];
        openEnded.video = options[2];
        openEnded.audio = options[3];
        openEnded.pdf = options[4];
        openEnded.document = false;
      }
    }
  },
};
const activityHelpers = {
  changeActivityType(
    currentActivity: IActivity,
    type: number,
    meta?: IWorksheetCreationMetaData
  ) {
    currentActivity.activity_type = type;
    let currentTeacherSelection: ISolution[] = [];
    if (currentActivity.problem?.teacher_solution) {
      currentTeacherSelection = currentActivity.problem?.teacher_solution;
    }
    if (type === 1) {
      const evaluationScale = EvaluationScale.QUANTITATIVE;
      currentActivity.problem = {
        uuid: "",
        problem_type: ProblemType.OPEN, //open, multiple-choices, true-false
        max_points: NaN,
        teacher_solution: currentTeacherSelection,
        open_ended: {
          uuid: "",
          photo: false,
          video: false,
          audio: false,
          text: false,
          pdf: false,
          document: false,
          max_num_files: meta?.max_num_files_solution ?? 0,
          max_size_mbytes: meta?.max_file_size_mbytes_question ?? 0,
          evaluation_scale: evaluationScale,
        },
        close_ended: undefined,
      };
      let currentTutorial: ITutorial;
      if (currentActivity.tutorial) {
        currentTutorial = currentActivity.tutorial;
      }
    } else {
      currentActivity.tutorial = {
        uuid: "",
        tutorialFiles: [],
      };
    }
  },
};

const REST_API_CALLS = {
  wks_info: {
    async createUpdate(params: {
      courseUUID: string;
      subchapterUUID: string;
      creationsType: string;
      worksheetType: string;
      difficulty: number;
      worksheetName: string;
      extraInfo: IFile | null;
      learningScenario: IFile | null;
      latitude: string;
      longitude: string;
      comment: string;
      is_ok: boolean | null;
      creation_status: number;
      uuid: string;
    }) {
      const {
        uuid,
        courseUUID,
        subchapterUUID,
        creationsType,
        worksheetType,
        difficulty,
        worksheetName,
        extraInfo,
        learningScenario,
        comment,
        creation_status,
        is_ok,
      } = params;
      const isLocation = worksheetType === "3";

      const formData = new FormData();
      formData.append("course", courseUUID);
      formData.append("worksheet", uuid);
      formData.append("subchapter", subchapterUUID);
      formData.append("type", creationsType);
      formData.append("wks_type", worksheetType);
      formData.append("difficulty_level", difficulty.toString());
      formData.append("name", worksheetName);
      formData.append("comment", comment);
      formData.append("creation_status", creation_status.toString());
      if (is_ok === null) {
        formData.append("is_ok", "");
      } else {
        formData.append("is_ok", JSON.stringify(is_ok));
      }

      if (isLocation || [true].some((t) => t)) {
        // TODO: Remove true as soon as location is implemented
        formData.append("latitude", "");
        formData.append("longitude", "");
      }
      if (!extraInfo) {
        formData.append(
          "extra_info",
          JSON.stringify({
            filename: "",
            filetype: "",
            filepath: "",
            isToUpdate: false,
            isToDelete: true,
          })
        );
        formData.append("extra_info_file", "");
      } else {
        formData.append("extra_info", JSON.stringify(extraInfo));
        if (extraInfo.isToUpdate) {
          const file = await fetchLocalIFile(extraInfo);
          formData.append("extra_info_file", file ? file : "");
        } else {
          formData.append("extra_info_file", "");
        }
      }

      if (!learningScenario) {
        formData.append(
          "learning_scenario",
          JSON.stringify({
            filename: "",
            filetype: "",
            filepath: "",
            isToUpdate: false,
            isToDelete: true,
          })
        );
        formData.append("learning_scenario_file", "");
      } else {
        formData.append("learning_scenario", JSON.stringify(learningScenario));
        if (learningScenario.isToUpdate) {
          const file = await fetchLocalIFile(learningScenario);
          formData.append("learning_scenario_file", file ? file : "");
        } else {
          formData.append("learning_scenario_file", "");
        }
      }

      const response = await requestService.post<
        | {
          router: string;
          wks_uuid: string;
          worksheet: IWorksheet;
        }
        | {
          error: string;
          msg: string;
        }
      >("create-update-worksheet", formData);
      return response;
    },
    async get(courseUUID: string) {
      const resp =
        await requestService.getWithParams<IWorksheetCreationMetaData>(
          "create-update-worksheet",
          {
            course: courseUUID,
          }

        );
      return resp;
    },
  },
  tasks: {
    // async create(tasks: ITask0[], worksheetUUID: string, courseUUID: string) {
    //   const formData = await serializeTasks(tasks);
    //   formData.append("worksheet", worksheetUUID);
    //   formData.append("course", courseUUID);
    //   const resp = await requestService.post<{ router: string }>(
    //     "create-update-tasks",
    //     formData
    //   );
    //   return resp;
    // },
    async update(tasks: ITask0[], worksheetUUID: string, courseUUID: string) {
      const formData = await serializeTasks(tasks);
      formData.append("worksheet", worksheetUUID);
      formData.append("course", courseUUID);

      const resp = await requestService.post<{ router: string }>(
        "update-worksheet-tasks",
        formData
      );
      return resp;
    },
  },
  async publish(params: {
    wksUUID: string;
    courseUUID: string;
    chapterUUID: string;
    subchapterUUID: string;
  }) {
    const { wksUUID, courseUUID, chapterUUID, subchapterUUID } = params;

    const resp = await requestService.post<{ router: string }>(
      "worksheet-publish",
      {
        worksheet: wksUUID,
        course: courseUUID,
        chapter: chapterUUID,
        subchapter: subchapterUUID,
      }
    );
    return resp;
  },
  //DELETE TO DELETE
  // async delete(wksUUID: string) {
  //   const resp = await requestService.post<{ router: string }>(
  //     "worksheet-delete",
  //     { worksheet: wksUUID }
  //   );
  //   return resp;
  // },
  async loadWks(uuid: string, courseUUID: string) {
    const params = { uuid };
    const resp1 = await requestService.getWithParams<IWorksheet>(
      "worksheet",
      params
    );
    if (!(200 <= resp1.status && resp1.status < 300)) throw resp1;

    let wksData: IWorksheet | undefined = undefined;
    let metaData: IWorksheetCreationMetaData | undefined = undefined;
    wksData = resp1.data;

    const resp2 =
      await requestService.getWithParams<IWorksheetCreationMetaData>(
        "create-update-worksheet",
        {
          course: courseUUID,
        }
      );
    if (!(200 <= resp2.status && resp2.status < 300)) throw resp2;
    metaData = resp2.data;
    metaData.isPage1Changed = false;
    metaData.isPage2Changed = false;
    await memoryManager.clear();
    return { wksData, metaData };
  },
};

function isDeltaEmptyOrOnlyNewlines(delta: Delta | null): boolean {
  if (!delta || !delta.ops) {
    return true;
  }
  return delta.ops.every(op => {
    if (typeof op.insert === 'string') {
      return op.insert.trim() === '';
    }
    return false;
  });
}
// Let's map the errors on every task containing multiple activities

class Validator {
  warningMessages: WksCreationErrorMessages;
  public errors: WksTaskErrors[] = [];

  constructor(warningMessages: WksCreationErrorMessages) {
    this.warningMessages = warningMessages;
  }

  public setWarningMessages(warningMessages: WksCreationErrorMessages) {
    this.warningMessages = warningMessages;
  }

  public validate(worksheet: IWorksheet): WksTaskErrors[] {
    this.errors = [];

    worksheet.tasks.forEach((task) => {
      if (!task.task.isToDelete) {
        const taskError = this.validateTask(task.task);
        this.errors.push(taskError);
      }
    });

    return this.errors;
  }
  public hasError(errors: WksTaskErrors[]): boolean {
    return errors.some((task) => {
      const isActivityError = task.activities.some((activity) =>
        Object.values(activity.errors).some((error) => error.isWarning)
      );
      const isTaskError = Object.values(task.errors).some((error) => error.isWarning);
      return isActivityError || isTaskError;
    });
  }

  private validateTask(task: ITask) {
    const taskError = this.createTaskError(task.uuid);


    const hasNonEmptyTextrtf = task.task_files?.some(
      (taskFile) => taskFile.textrtf && !isDeltaEmptyOrOnlyNewlines(taskFile.textrtf)
    ) ?? false;

    const hasValidTaskFile = task.task_files?.some(
      (taskFile) => !taskFile.file.isToDelete && taskFile.file.filename
    ) ?? false;

    if ((!hasNonEmptyTextrtf && !hasValidTaskFile)) {
      taskError.errors.question.message = this.warningMessages.task_file_required;
      taskError.errors.question.isWarning = true;
    }

    task.activities.forEach((activity) => {
      if (!activity.isToDelete) {
        const activityError = this.validateActivity(activity);
        taskError.activities.push(activityError);
      }
    });

    return taskError;
  }

  private validateActivity(activity: IActivity) {
    const activityError = this.createActivityError(activity.uuid);

    if (activity.activity_type === 1) {
      if (activity.problem) {
        this.validateProblem(activity.problem, activityError);
      } else {
        console.error("activity.activity_type key mismatch!", activity);
      }
    } else {
      if (activity.tutorial) {
        /// we dont need to validate the tutorial as it can be empty
        //this.validateTutorial(activity.tutorial!, activityError);
      } else {
        console.error("activity.activity_type key mismatch!", activity);
      }
    }

    const hasAnyError = Object.values(activityError.errors).some(
      (error) => error.isWarning
    );
    if (hasAnyError) {
      activityError.errors.required_fields.message =
        this.warningMessages.required_fields;
      activityError.errors.required_fields.isWarning = true;
    }

    return activityError;
  }
  private validateProblem(problem: IProblem, activityError: WksActivityErrors) {
    if (problem.max_points !== undefined && problem.max_points < 0) {
      activityError.errors.points.message = this.warningMessages.points;
      activityError.errors.points.isWarning = true;
    }

    if (
      problem.max_points === undefined ||
      problem.max_points == 0 ||
      isNaN(problem.max_points)
    ) {
      activityError.errors.points.message = this.warningMessages.points;
      activityError.errors.points.isWarning = true;
    }


    if (!problem.teacher_solution || problem.teacher_solution.length === 0) {
      activityError.errors.solution_file.message = this.warningMessages.solution_file;
      activityError.errors.solution_file.isWarning = true;
    } else {
      let hasValidSolution = false;

      for (const solution of problem.teacher_solution) {
        if (!solution.solution_files || solution.solution_files.length === 0) {
          activityError.errors.solution_file.message = this.warningMessages.solution_file;
          activityError.errors.solution_file.isWarning = true;
          break;
        }

        const hasNonEmptyTextrtf = solution.solution_files.some( //false
          (solutionFile) => solutionFile?.textrtf && !isDeltaEmptyOrOnlyNewlines(solutionFile.textrtf)
        );

        const hasValidFile = solution.solution_files.some( //true
          (solutionFile) => solutionFile?.file && !solutionFile.file.isToDelete && solutionFile.file.filename
        );

        // const hasInvalidFile = solution.solution_files.some(
        //   (solutionFile) => !solutionFile?.file?.filename && (!solutionFile?.textrtf || isDeltaEmptyOrOnlyNewlines(solutionFile.textrtf))
        // );

        if ((!hasNonEmptyTextrtf && !hasValidFile)) {
          activityError.errors.solution_file.message = this.warningMessages.solution_file;
          activityError.errors.solution_file.isWarning = true;
          break;
        } else {
          hasValidSolution = true;
        }
      }

      if (!hasValidSolution) {
        activityError.errors.solution_file.message = this.warningMessages.solution_file;
        activityError.errors.solution_file.isWarning = true;
      }
    }

    if (problem.open_ended) {
      this.validateOpenEnded(problem.open_ended, activityError);
    } else {
      this.validateClosedEnded(problem, activityError);
    }
  }

  private validateTutorial(tutorial: ITutorial, activity: WksActivityErrors) {
    if (
      tutorial.tutorialFiles?.length === 0 ||
      !tutorial.tutorialFiles?.some((file) => !file.file.isToDelete)
    ) {
      activity.errors.tutorial_file_required.message =
        this.warningMessages.tutorial_file_required;
      activity.errors.tutorial_file_required.isWarning = true;
    }
  }
  private validateOpenEnded(
    problem: IOpenEnded,
    activityError: WksActivityErrors
  ) {
    if (
      [
        problem.audio,
        problem.pdf,
        problem.photo,
        problem.text,
        problem.video,
      ].every((v) => v === false)
    ) {
      console.log("FOI AQUI")
      activityError.errors.open_answer.message =
        this.warningMessages.open_answer;
      activityError.errors.open_answer.isWarning = true;
    }
  }
  private validateClosedEnded(
    problem: IProblem,
    activityError: WksActivityErrors
  ) {
    if (
      problem.problem_type === ProblemType.TRUE_FALSE &&
      (problem.close_ended === null ||
        problem.close_ended?.correct_answer_pos === null)
    ) {
      activityError.errors.yes_no_answer.message =
        this.warningMessages.yes_no_answer;
      activityError.errors.yes_no_answer.isWarning = true;
    } else if (
      problem.problem_type === ProblemType.MULTIPLE_CHOICES &&
      (problem.close_ended === null ||
        problem.close_ended === undefined ||
        problem.close_ended.correct_answer_pos === null ||
        Number.isNaN(problem.close_ended.correct_answer_pos))
    ) {
      activityError.errors.multiple_right_answer.message =
        this.warningMessages.multiple_right_answer;
      activityError.errors.multiple_right_answer.isWarning = true;
    } else if (problem.problem_type === ProblemType.MATCH_CASE) {
      console.error("Testing for Errors for Match Case not implemented yet");
      console.warn("Error Message for Match Case is missing (11.01.2024)");
    }
  }

  private createTaskError(uuid: string): WksTaskErrors {
    return {
      uuid: uuid,
      errors: {
        question: {
          message: this.warningMessages.task_file_required,
          isWarning: false,
        },
      },
      activities: [],
    };
  }
  private createActivityError(uuid: string): WksActivityErrors {
    return {
      uuid: uuid,
      errors: {
        open_answer: {
          message: this.warningMessages.open_answer,
          isWarning: false,
        },
        multiple_right_answer: {
          message: this.warningMessages.multiple_right_answer,
          isWarning: false,
        },
        yes_no_answer: {
          message: this.warningMessages.yes_no_answer,
          isWarning: false,
        },
        points: { message: this.warningMessages.points, isWarning: false },
        solution_file: {
          message: this.warningMessages.solution_file,
          isWarning: false,
        },
        video_solution: {
          message: this.warningMessages.video_solution,
          isWarning: false,
        },
        tutorial_file_required: {
          message: this.warningMessages.tutorial_file_required,
          isWarning: false,
        },
        required_fields: {
          message: this.warningMessages.required_fields,
          isWarning: false
        }
      },
    };
  }
}
interface WksTaskErrors {
  uuid: string;
  errors: { question: WksCreationError };
  activities: WksActivityErrors[];
}
interface WksActivityErrors {
  uuid: string;
  errors: {
    open_answer: WksCreationError;
    multiple_right_answer: WksCreationError;
    yes_no_answer: WksCreationError;
    points: WksCreationError;
    solution_file: WksCreationError;
    video_solution: WksCreationError;
    tutorial_file_required: WksCreationError;
    required_fields: WksCreationError;
  };
}

interface WksCreationError {
  message: string;
  isWarning: boolean;
}

interface WksCreationErrors {
  question: WksCreationError;
  open_answer: WksCreationError;
  correct_answer: WksCreationError;
  multiple_right_answer: WksCreationError;
  yes_no_answer: WksCreationError;
  points: WksCreationError;
  solution_file: WksCreationError;
  video_solution: WksCreationError;
  tutorial_file_required: WksCreationError;
}

interface WksCreationErrorMessages {
  task_file_required: string;
  tutorial_file_required: string;
  open_answer: string;
  multiple_right_answer: string;
  yes_no_answer: string;
  points: string;
  solution_file: string;
  video_solution: string;
  required_fields: string
}

export {
  useWorksheetCreationStore,
  problemHelpers,
  activityHelpers,
  IWorksheetCreationMetaData,
  REST_API_CALLS,
  Validator,
  WksCreationErrors,
  WksCreationError,
  WksTaskErrors,
  WksActivityErrors,
  IActivity,
  ProblemType,
  IFile,
  ITask0,
  IFileGroup,
  ITaskFile,
  ITutorialFile,
  IWorksheet,
  ITask,
  IProblem,
  ITutorial,
  ISolution,
};
